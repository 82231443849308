<template>
    <div class="container">
        <div class="form">
          <van-uploader :deletable="true" v-model="fileList" :after-read="afterRead" @delete="deleteImg" :max-count="1"/>
          <van-field v-model="form.name" clearable size="large"  label="姓名"  required placeholder="请输入真实姓名"  />
          <van-field v-model="form.phone" clearable size="large" required label="手机号"  placeholder="请填写手机号" />
          <van-field v-model="sexName" clearable readonly size="large" label="性别"  required right-icon="arrow-down" placeholder="请选择性别" @click="onShowSex"  />
          <van-field v-model="form.address" clearable readonly  @click="showArea=true" size="large" label="所在城市" placeholder="请填写目前生活城市" />
          <van-area title="选择地区" @cancel="showArea=false" :area-list="areaList" v-show="showArea" @confirm="confirmArea" />

          <van-field v-model="form.advantage" clearable size="large" required label="个人优势"  placeholder="请填写个人优势" />
          <van-field v-model="form.age" clearable size="large" required label="年龄"  placeholder="请填写真实年龄" />
          <van-field v-model="form.insurance" clearable readonly size="large" label="有保险"  required right-icon="arrow-down" placeholder="是否有保险" @click="showInsurance=true"  />
          <!--擅长工种-->
          <div class="CheckGroupBox">
            <label>擅长工种</label>
            <CustomCheckGroup v-model="form.workType" :max-checked="5" :items="workTypes" ></CustomCheckGroup>
          </div>


          <!--资格证书-->
          <div class="CheckGroupBox">
            <label>资格证书</label>
            <van-uploader :deletable="true" v-model="certificateList" :after-read="afterReadCertificate" :before-delete="deleteImgCertificateBefore" @delete="deleteImgCertificate" :max-count="4"/>
          </div>


          <!--收款码-->
          <div class="CheckGroupBox">
            <label>收款码</label>
            <van-uploader :deletable="true" v-model="payImgList" :after-read="afterReadPayImg" @delete="deletePayImg" :max-count="1"/>
          </div>

          <van-button class="btn" @click="save" round type="info">完成</van-button>
          <div>
            <van-button class="skip" @click="toIndex" style="border:none;background-color:#FFF;color:#B7BAC0" type="info">跳过</van-button>
          </div>
        </div>
        <van-action-sheet v-model="show" :actions="sexs" @select="onSelect" />
      <van-action-sheet v-model="showInsurance" :actions="insurances" @select="onSelectInsurance" />
    </div>
</template>

<script>
    import {
        upload,
        editUser,
      me
    } from "../../api/login";
    import {
        Notify,
        ActionSheet
    } from 'vant';
    import store from '../../store'
    import {
        mapMutations
    } from 'vuex'
    import {
        SET_USER
    } from '../../const.js'
    import { areaList } from '../../api/area';
    import {getDicts} from "../../api/dict";
    export default {
        data() {
            return {
                areaList: areaList,
                showArea:false,
                showInsurance:false,
                back: 0,
                show: false,
                sexName: '男',
                type: '1',
                workTypes:[
                  {
                    label:"焊工",
                    value:"焊工"
                  },{
                    label:"电工",
                    value:"电工"
                  }
                ],
                sexs: [{name: '男'}, {name: '女'}],
                insurances: [{name: '是'}, {name: '否'}],
                form: {
                  id: undefined,
                  avatar: undefined,
                  gender: undefined,
                  phone: '1',
                  name: undefined,
                  address: undefined,
                  advantage: undefined,
                  age: undefined,
                  cardBack: undefined,
                  cardJust: undefined,
                  certificate: undefined,
                  insurance: undefined,
                  workAge: undefined,
                  workType: [],
                  payImg: undefined
                },
                fileList: [],//头像
                payImgList: [],//收款码
                certificateList:[]//资格证书
            };
        },
        created(e) {


            // this.sexName = user.sex==1?'男':'女'
            //
            // if (this.form.avatar) {
            //     this.fileList.push({
            //         url: this.form.avatar
            //     })
            // }

            console.info(this.form, )
        },
        async mounted() {
          this.back = this.$route.query.back
          this.getMe();
          this.loadDict();
        },
        
        methods: {
            ...mapMutations([SET_USER]),
          confirmArea(e){
            let sheng = e[0];
            let city = e[1];
            let qu = e[2];
            this.form.address = sheng.name +" "+city.name+" "+qu.name
            this.showArea=false;
          },
            selectType(e) {
                this.type = e.currentTarget.dataset.type;
            },
            onShowSex() {
                this.show = true;
            },
            onSelect(item) {
                this.show = false;
                this.sexName = item.name;
                this.form.gender = item.name == '男' ? '0' : '1'
            },
          onSelectInsurance(item) {
            this.showInsurance = false;
            this.form.insurance = item.name
          },
          deleteImgCertificateBefore(e,name){
            console.info("deleteImgCertificateBefore", e,name)
            this.form.certificate.splice(name.index,1)
            this.certificateList.splice(name.index,1)
            console.info("deleteImgCertificateBefore", this.form)
          },
            deleteImgCertificate(e){
                console.info("deleteImg", e)
            },
            async afterReadCertificate(file) {
              const forms = new FormData();
              forms.append("file", file.file); // 获取上传图片信息
              let r = await upload(forms)
              this.form.certificate.push(r.data.url)
              console.info("afterReadCertificate",this.certificateList)
            },

          deletePayImg(){
            console.info("deleteImg", )
            this.payImgList= []
            this.form.payImg='';
          },
          async afterReadPayImg(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            this.form.payImg = r.data.url
          },

          deleteImg(){
            console.info("deleteImg", )
            this.fileList= []
            this.form.avatar='';
          },
          async afterRead(file) {
            const forms = new FormData();
            forms.append("file", file.file); // 获取上传图片信息
            let r = await upload(forms)
            this.form.avatar = r.data.url
          },

          async save() {
            console.info("save",this.form)
            if (!this.form.name) {
                Notify({
                    type: 'warning',
                    message: '请输入真实姓名'
                });
                return false;
            }
            let r = await editUser(this.form)
            Notify({ type: 'success', message: '修改成功' });
            setTimeout(() => {
              if(this.back){
                history.back();
              }else{
                this.$router.push({
                  path: '/'
                })
              }
            }, 500)

          },
          async loadDict() {

            let r = await getDicts({name:'工人类型'})
            console.info("me", r)
            this.workTypes = r.data
          },
          async getMe() {

            let r = await me()
            console.info("me",r)

            this.form.id= r.data.id
            this.form.avatar= r.data.avatar
            this.form.gender= r.data.gender
            this.form.phone= r.data.phone
            this.form.name= r.data.name
            this.form.address= r.data.address
            this.form.advantage= r.data.advantage
            this.form.age= r.data.age
            this.form.cardBack= r.data.cardBack
            this.form.cardJust= r.data.cardJust
            this.form.certificate= r.data.certificate
            this.form.insurance= r.data.insurance
            this.form.workAge= r.data.workAge
            this.form.workType= r.data.workType?r.data.workType:[]
            this.form.payImg= r.data.payImg
            this.sexName = r.data.gender==0?'男':'女'

            if (this.form.avatar) {
                this.fileList.push({
                    url: this.form.avatar
                })
            }
            if (this.form.certificate) {
              this.form.certificate.forEach(c=>{
                this.certificateList.push({
                  url: c
                })
              })
            }
            if (this.form.payImg) {
              this.payImgList.push({
                url: this.form.payImg
              })
            }

          },

          toIndex() {
              this.$router.push({
                  path: '/'
              })
          },

        },
    };
</script>

<style lang="less" scoped>
    
    .container {
        padding: 0px;
        background: #FFFFFF;
    }
    
    .van-uploader__preview-image {
        border-radius: 50%;
    }

    .CheckGroupBox{
      display: flex;
      padding: 15px 5px 5px 15px;
      label{
        padding-top: 5px;
        color: #646566;
        text-align: left;
        word-wrap: break-word;
        width: 5.3em;
        font-size: 16px;
      }
    }
    
    body {
        width: 100%;
        min-height: 100%;
        .form {
            padding-top: 20px;
            text-align: center;
            .van-uploader__upload {
                border-radius: 50px;
            }
            .van-cell--large .van-cell__title {
                font-size: 14px;
            }
            .van-field__label {
                margin-right: 0px;
            }
            .van-cell--large {
                padding-top: 24px;
                padding-bottom: 6px;
            }
            .van-cell::after {
                border-bottom: 1px solid #ECECEC;
            }
                // ::v-deep .van-cell--required {
    //     &:before{
    //         content: ''
    //     }
        .van-cell__title {
            span {
            position: relative;
            color: #016AED;
                &:before {
                    position: absolute;
                    right: -8px;
                    color: #ee0a24;
                    font-size: 14px;
                    content: '*';
                }
            }
        }
    // }
            .btn {
                margin-top: 30px;
                width: 144px;
                height: 45px;
                background: #016AED;
                box-shadow: 0px 3px 11px 2px rgba(219, 219, 219, 0.6);
                border-radius: 21px;
                font-weight: 400;
                font-size: 16px;
            }
            .skip {
                padding: 10px;
                margin-top: 20px;
                width: 50px;
                height: 13px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #B7BAC0;
                line-height: 16px;
            }
        }
    }
</style>

<style>
#app .van-field__label {
    width: 5.3em;
}
</style>